import { BrowserRouter, Routes, Route, useLocation } from 'react-router-dom';
import Footer from './core/features/Footer';
import Home from './screens/Home';
import Header from './core/features/header/Header';
import Contacts from './screens/Contacts';
import About from './screens/About';
import Services from './screens/Services';
import Applicants from './screens/Applicants';
import Projects from './screens/Projects';
import useContentful from './core/customHooks/useContentful';
import { useEffect, useState } from 'react';
import FooterPage from './screens/FooterPage';
import ProjectPage from './screens/ProjectPage';
import usePathConverter from './core/customHooks/usePathConverter';
import CardLP from './screens/CardLP';
import ProfessionsPage from './screens/ProfessionsPage';
import ProfessionLP from './screens/ProfessionLP';
import NewsLP from './screens/NewsLP';
import Overlay from './core/features/Overlay';
import Loading from './core/features/Loading';
import DepartmentLP from './screens/DepartmentLP';
import NewsPage from './screens/NewsPage';
import CookieConsent from './core/components/CookieConsent';

function App() {
	const [loading, setLoading] = useState(true);
	const { getData: getAboutData } = useContentful('about');
	const { getData: getAdministrativeLegalData } = useContentful(
		'administrativeAndLegalInformation'
	);
	const { getData: getDepartmentsData } = useContentful('department');
	const { getData: getNewsData } = useContentful('news');
	const { getData: getEmployeesData } = useContentful('employee');
	const { getData: getServiceData } = useContentful('service');
	const { getData: getApplicantData } = useContentful('applicant');
	const { getData: getFooterPageData } = useContentful('footerPage');
	const { getData: getProjectPageData } = useContentful('project');
	const { getData: getCardPageData } = useContentful('cardPage');
	const { getData: getProfessionata } = useContentful('profession');

	const [aboutData, setAboutData] = useState([]);
	const [ALData, setALData] = useState([]);
	const [departmentsData, setDepartmentsData] = useState([]);
	const [newsData, setNewsData] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);
	const [serviceData, setServiceData] = useState([]);
	const [applicantData, setApplicantData] = useState([]);
	const [footerPageData, setFooterPageData] = useState([]);
	const [projectPageData, setProjectPageData] = useState([]);
	const [cardPageData, setCardPageData] = useState([]);
	const [professionData, setProfessionData] = useState([]);

	const { convertTextToPath } = usePathConverter();

	const getActiveNews = (newsData) => {
		if (!newsData || newsData.length === 0) return null;

		const activeNews = newsData.filter((item) => item.fields.active).shift();

		return activeNews || null;
	};

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true);
			try {
				const [
					aboutResponse,
					ALResponse,
					departmentsResponse,
					newsResponse,
					employeeResponse,
					serviceResponse,
					applicantResponse,
					footerPageResponse,
					projectPageResponse,
					cardPageResponse,
					professionResponse,
				] = await Promise.all([
					getAboutData(),
					getAdministrativeLegalData(),
					getDepartmentsData(),
					getNewsData(),
					getEmployeesData(),
					getServiceData(),
					getApplicantData(),
					getFooterPageData(),
					getProjectPageData(),
					getCardPageData(),
					getProfessionata(),
				]);
				setAboutData(aboutResponse);
				setALData(ALResponse);
				setDepartmentsData(departmentsResponse);
				setNewsData(newsResponse);
				setEmployeeData(employeeResponse);
				setServiceData(serviceResponse);
				setApplicantData(applicantResponse);
				setFooterPageData(footerPageResponse);
				setProjectPageData(projectPageResponse);
				setCardPageData(cardPageResponse);
				setProfessionData(professionResponse);
			} catch (error) {
				console.error('Error fetching data:', error);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	if (loading) {
		return <Loading />;
	}
	const activeNews = getActiveNews(newsData);

	const newsLPRoutes = newsData.map((item) => {
		const path = convertTextToPath(item.fields.title);

		return (
			<Route
				key={path}
				path={`naujienos/${path}`}
				element={
					<NewsLP
						title={item.fields.title}
						date={item.fields.date}
						description={item.fields.description}
						img={item.fields.image.fields.file.url}
					/>
				}
			/>
		);
	});

	const cardLPRoutes = cardPageData.map((item) => {
		const path = convertTextToPath(item.fields.title);

		return (
			<Route
				key={path}
				path={path}
				element={
					<CardLP
						title={item.fields.title}
						images={item.fields.images}
						description={item.fields.description}
						documents={item.fields.documents}
						phone={item.fields.phone}
						email={item.fields.email}
						location={item.fields.adress}
						ytLink={item.fields.youtubeUrl}
						regUrl={item.fields.registrationUrl}
						tables={item.fields.tables}
						links={item.fields.usefulLinks}
					/>
				}
			/>
		);
	});
	const sortedServiceData = serviceData.sort((a, b) =>
		a.fields.title.localeCompare(b.fields.title)
	);
	const serviceRoutes = serviceData.map((item) => (
		<Route
			key={item.fields.route}
			path={`/paslaugos/${item.fields.route}`}
			element={<Services state={item.fields.route} data={sortedServiceData} />}
		/>
	));

	const applicantRoutes = applicantData.map((item) => {
		return (
			<Route
				key={item.fields.route}
				path={`/stojantiesiems/${item.fields.route}`}
				element={<Applicants state={item.fields.route} data={applicantData} />}
			/>
		);
	});

	const departmentRoutes = departmentsData.map((item) => {
		const path = convertTextToPath(item.fields.name);
		return (
			<Route
				key={path}
				path={`/apie-mus/padaliniai/${path}`}
				element={
					<DepartmentLP
						title={item.fields.name}
						description={item.fields.description}
						documents={item.fields.documents}
					/>
				}
			/>
		);
	});
	const mainDepartment = departmentsData.find((dep) => dep.fields.id === 1);

	const footerRoutes = footerPageData.map((item) => {
		const path = convertTextToPath(item.fields.title);

		const images = item.fields.images
			? item.fields.images.map((image) => image.fields.file.url)
			: [];
		return (
			<Route
				key={path}
				path={path}
				element={
					<FooterPage
						title={item.fields.title}
						mainParagraph={item.fields.mainParagraph}
						images={images}
						expandingParagraph={item.fields.expandingParagraphs}
						documents={item.fields.documents}
						storiesData={item.fields.successStories}
					/>
				}
			/>
		);
	});

	const projectRoutes = projectPageData.map((item) => {
		const path = `${item.fields.type}/${convertTextToPath(item.fields.title)}`;

		function findNewsArticle(newsData) {
			if (!newsData || newsData.length === 0) {
				return null;
			}
			const foundArticle = newsData.find(
				(news) => news.fields.project?.fields?.title === item.fields.title
			);

			return foundArticle;
		}

		const newsArticle = findNewsArticle(newsData);

		return (
			<Route
				key={path}
				path={`/projektai/${path}`}
				element={
					<ProjectPage
						images={item.fields.logo.map((logo) => logo.fields.file.url)}
						title={item.fields.title}
						number={item.fields.number}
						startDate={item.fields.startDate}
						endDate={item.fields.endDate}
						description={item.fields.description}
						aim={item.fields.aim}
						results={item.fields.results}
						partners={item.fields.partners}
						budget={item.fields.budget}
						newsArticle={newsArticle}
					/>
				}
			/>
		);
	});

	const professionLPRoutes = professionData.map((item) => {
		const path = `${convertTextToPath(item.fields.title)}`;

		return (
			<Route
				key={path}
				path={`/profesijos/${path}`}
				element={
					<ProfessionLP
						title={item.fields.title}
						applicationUrl={item.fields.applicationUrl}
						howToApplyUrl={item.fields.howToApplyUrl}
						about={item.fields.about}
						expectation={item.fields.expectation}
						audience={item.fields.audience}
						phone={item.fields.contactPhone}
						email={item.fields.contactEmail}
						benefits={item.fields.benefits}
						img={item.fields.image.fields.file.url}
					/>
				}
			/>
		);
	});
	function ScrollToTop() {
		const { pathname } = useLocation();

		useEffect(() => {
			window.scrollTo(0, 0);
		}, [pathname]);

		return null;
	}
	return (
		<BrowserRouter>
			<ScrollToTop />
			<Header
				department={mainDepartment}
				applicantData={applicantData}
				serviceData={serviceData}
			/>
			<Routes>
				<Route
					path="/"
					element={<Home news={newsData} activeNews={activeNews} />}
				/>
				<Route
					path="/kontaktai"
					element={
						<Contacts employees={employeeData} departments={departmentsData} />
					}
				/>
				<Route
					path="/apie-mus/bendra-informacija"
					element={
						<About
							state={'about'}
							data={aboutData}
							ALData={ALData}
							departmentsData={departmentsData}
						/>
					}
				/>
				<Route
					path="/apie-mus/padaliniai"
					element={
						<About
							state={'departments'}
							ALData={ALData}
							data={aboutData}
							departmentsData={departmentsData}
						/>
					}
				/>
				<Route
					path="/apie-mus/struktura"
					element={
						<About
							state={'structure'}
							ALData={ALData}
							data={aboutData}
							departmentsData={departmentsData}
						/>
					}
				/>
				<Route
					path="/apie-mus/administracine-informacija"
					element={
						<About
							state={'administrative'}
							ALData={ALData}
							data={aboutData}
							departmentsData={departmentsData}
						/>
					}
				/>
				<Route
					path="/apie-mus/dokumentai"
					element={
						<About
							state={'documents'}
							ALData={ALData}
							data={aboutData}
							departmentsData={departmentsData}
						/>
					}
				/>
				<Route
					path="/apie-mus/teisine-informacija"
					element={
						<About
							state={'legal'}
							ALData={ALData}
							data={aboutData}
							departmentsData={departmentsData}
						/>
					}
				/>
				<Route
					path="/profesijos"
					element={<ProfessionsPage professions={professionData} />}
				/>
				{newsLPRoutes}
				{serviceRoutes}
				{departmentRoutes}
				{applicantRoutes}
				{footerRoutes}
				{projectRoutes}
				{cardLPRoutes}
				{professionLPRoutes}
				<Route
					path="/projektai/tarptautiniai"
					element={
						<Projects state={'tarptautiniai'} projectData={projectPageData} />
					}
				/>
				<Route
					path="/projektai/infrastrukturiniai"
					element={
						<Projects
							state={'infrastrukturiniai'}
							projectData={projectPageData}
						/>
					}
				/>
				<Route
					path="/projektai/zmogiskuju-istekliu"
					element={
						<Projects
							state={'zmogiskuju-istekliu'}
							projectData={projectPageData}
						/>
					}
				/>
				<Route path="/naujienos" element={<NewsPage news={newsData} />} />
			</Routes>
			<Footer footerPages={footerPageData} />
			{activeNews && (
				<Overlay
					img={activeNews.fields.image.fields.file.url}
					title={activeNews.fields.title}
					active={activeNews.fields.active}
					date={activeNews.fields.date}
				/>
			)}

			<CookieConsent />
		</BrowserRouter>
	);
}

export default App;
