import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import NewsCard from '../core/components/NewsCard';

export default function ProjectPage({
	images,
	title,
	number,
	startDate,
	endDate,
	description,
	aim,
	results,
	partners,
	budget,
	newsArticle,
}) {
	return (
		<main className="pb-32 pt-40 flex flex-col gap-8 pr-40 max-2xl:pr-16 max-sm:pr-8 bg-neutral-50 ">
			<span className="flex flex-row gap-8 max-w-xs items-center pl-40 max-2xl:pl-16 max-sm:pl-8">
				{images.map((image, index) => (
					<img
						key={index}
						className="object-cover"
						src={image}
						alt={'Partner Logo'}
					/>
				))}
			</span>
			<div className="pl-40 max-2xl:pl-16 max-sm:pl-8">
				<h1>{title}</h1>
				<p>{number}</p>
				<div className="flex flex-row gap-4">
					<p>{startDate}</p>
					<span>-</span>
					<p>{endDate}</p>
				</div>
			</div>
			<div className=" pl-40 max-2xl:pl-16 max-sm:pl-8 contentful-content bg-white p-8 rounded-e-xl shadow-md">
				{documentToReactComponents(description)}
			</div>
			{aim && (
				<div>
					<h2 className="py-2 pr-4 pl-40 max-2xl:pl-16 max-sm:pl-8 bg-yellow w-fit rounded-e-full shadow-md mb-8 mt-16">
						Tikslas
					</h2>
					<ReactMarkdown
						className={
							'pl-40 max-2xl:pl-16 max-sm:pl-8 contentful-content bg-white p-8 shadow-md rounded-e-xl'
						}
					>
						{aim}
					</ReactMarkdown>
				</div>
			)}

			{results && (
				<div>
					<h2 className="py-2 pr-4 pl-40 max-2xl:pl-16 max-sm:pl-8 bg-green text-white w-fit rounded-e-full mb-8 shadow-md">
						Rezultatai
					</h2>
					<ReactMarkdown
						className={
							'pl-40 max-2xl:pl-16 max-sm:pl-8 contentful-content bg-white text-lg rounded-e-xl shadow-md p-8'
						}
					>
						{results}
					</ReactMarkdown>
				</div>
			)}
			{partners && (
				<ul className="">
					<span className="flex items-center justify-center gap-4 py-2 pr-4 pl-40 max-2xl:pl-16 max-sm:pl-8 bg-cyan text-white w-fit rounded-e-full mb-8">
						<h2 className="">Projekto partneriai</h2>
					</span>
					<div className="bg-white py-8 rounded-e-xl shadow-md">
						{partners.map((partner, index) => (
							<li
								key={index}
								className="py-2 pr-4 pl-40 max-2xl:pl-16 max-sm:pl-8"
							>
								<p>{partner}</p>
							</li>
						))}
					</div>
				</ul>
			)}

			{budget && (
				<div>
					<h2 className="py-2 pr-4 pl-40 max-2xl:pl-16 max-sm:pl-8 ">
						Biudžetas
					</h2>
					<p className="py-2 pr-4 pl-40 max-2xl:pl-16 max-sm:pl-8 bg-white rounded-e-full w-fit shadow-md">
						{budget}
					</p>
				</div>
			)}
			{newsArticle && (
				<div className="flex px-40 max-2xl:px-16 max-sm:px-8 pt-8">
					<NewsCard
						heading={newsArticle.fields.title}
						image={newsArticle.fields.image.fields.file.url}
						date={newsArticle.fields.date}
						shortDescription={newsArticle.fields.shortDescription}
					/>
				</div>
			)}
		</main>
	);
}
